// Use important directive to override MUI component styles
@mixin h1($important: false) {
  font-size: if($important, 28px !important, 28px);
  line-height: if($important, 36px !important, 36px);
  font-weight: if($important, bold !important, bold);
}

@mixin h2($important: false) {
  font-size: if($important, 24px !important, 24px);
  line-height: if($important, 32px !important, 32px);
  font-weight: if($important, 600 !important, 600);
}

@mixin h3($important: false) {
  font-size: if($important, 20px !important, 20px);
  line-height: if($important, 28px !important, 28px);
  font-weight: if($important, 600 !important, 600);
}

@mixin h4($important: false) {
  font-size: if($important, 16px !important, 16px);
  line-height: if($important, 24px !important, 24px);
  font-weight: if($important, 600 !important, 600);
}

@mixin subtitle($important: false) {
  font-size: if($important, 14px !important, 14px);
  line-height: if($important, 20px !important, 20px);
  font-weight: if($important, 600 !important, 600);
}

@mixin subtitleSmall($important: false) {
  font-size: if($important, 12px !important, 12px);
  line-height: if($important, 16px !important, 16px);
  font-weight: if($important, 600 !important, 600);
}

@mixin bodyMediumLight($important: false) {
  font-size: if($important, 14px !important, 14px);
  line-height: if($important, 20px !important, 20px);
  font-weight: if($important, 400 !important, 400);
}

@mixin bodyMedium($important: false) {
  font-size: if($important, 14px !important, 14px);
  line-height: if($important, 20px !important, 20px);
  font-weight: if($important, 500 !important, 500);
}

@mixin bodySmallLight($important: false) {
  font-size: if($important, 12px !important, 12px);
  line-height: if($important, 16px !important, 16px);
  font-weight: if($important, 400 !important, 400);
}

@mixin bodySmall($important: false) {
  font-size: if($important, 12px !important, 12px);
  line-height: if($important, 16px !important, 16px);
  font-weight: if($important, 500 !important, 500);
}

@mixin bodyExtraSmall($important: false) {
  font-size: if($important, 10px !important, 10px);
  line-height: if($important, 12px !important, 12px);
  font-weight: if($important, 500 !important, 500);
}

@mixin linkTextSmall($important: false) {
  font-size: if($important, 12px !important, 12px);
  line-height: if($important, 16px !important, 16px);
  font-weight: if($important, 600 !important, 600);
  text-decoration: if($important, underline !important, underline);

  &:hover {
    text-decoration: if($important, none !important, none);
  }
}

@mixin linkTextExtraSmall($important: false) {
  font-size: if($important, 10px !important, 10px);
  line-height: if($important, 14px !important, 14px);
  font-weight: if($important, 500 !important, 500);
  text-decoration: if($important, underline !important, underline);

  &:hover {
    text-decoration: if($important, none !important, none);
  }
}

@mixin buttonFont($important: false) {
  font-size: if($important, 12px !important, 12px);
  line-height: if($important, 16px !important, 16px);
  font-weight: if($important, 600 !important, 600);
}
